<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text>
          <p>Run the rules for the selected Bing account/campaigns. The following rules will be applied after import:</p>
          <ul>
            <li>Delete 55-64, age_unknown and gender_unknown ad groups</li>
            <li>Change age group names 35-44 to 35-49 and 45-54 to 50-64</li>
            <li>Apply a -90% bid multiplier to demographic criteria other than those that apply to the ad group</li>
          </ul>
          <p class="mt-2" style="color: red;">Currently, the rules may only be applied one time per campaign. The Validate button checks whether the rules can be applied to the selected campaigns.</p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-form ref="form">
              <v-row>

                <v-col md="4">
                  <BingAccountAutoComplete
                    v-model="selectedAccountID"
                    @changed="accountChanged"
                    @refreshing="accountsRefreshing"
                  ></BingAccountAutoComplete>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <BingCampaignsMultiAutoComplete 
                    v-model="selectedCampaigns"
                    :accountID="selectedAccountID"
                    :mandatory="false"
                    @changed="campaignsChanged"
                  ></BingCampaignsMultiAutoComplete>
                </v-col>

                <v-col>
                  <v-btn class="mt-2" color="success" :loading="validating" @click="runRules(true)">Validate</v-btn>
                  <v-btn class="mt-2 ml-4" color="primary" :disabled="!validated" :loading="running" @click="runRules(false)">Run</v-btn>
                </v-col>

              </v-row>

              <v-row>
                <v-col md="12">
                  <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{errorMsg}}</v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <SystemJobsTable :headers="jobHeaders" :jobType="jobType" :updateKey="progressTableKey"></SystemJobsTable>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: "RunCampaignRules",

  data() {
    return {
      progressTableKey: "init",
      jobType: "Bing - Run rules for campaigns",
      jobHeaders: [
        { text: "User", value: "user_name" },
        { text: "Account name", value: "text1" },
        { text: "Account Id", value: "text2" },
        { text: "Campaigns", value: "text3"  },
        { text: "Status", value: "status" },
        { text: 'Entry at', value: 'entry_at_time' },
        { text: "Completed at", value: "completed_at_time" },
        { text: 'External message', value: 'external_message' },
      ],
      selectedAccountID: 0,
      selectedCampaigns: [],
      validated: false,
      validating: false,
      running: false,
      showErrorAlert: false,
      errorMsg: '',
      lsSelectedBingAccount: "runCampaignRules_selectedBingAccount",
      lsSelectedCampaigns: "runCampaignRules_selectedCampaigns",
    }
  },

  mixins: [formsMixin],

  computed: {
    bingAccounts() {
      return this.$store.state.bing.accounts
    },
    runImportRulesEndpoint() {
      return "/a/bing/accounts/" + this.selectedAccountID + "/run-campaign-rules"
    },
  },

  components: {
    BingAccountAutoComplete: () => import('@/components/autocompletes/BingAccountAutoComplete.vue'),
    BingCampaignsMultiAutoComplete: () => import('@/components/autocompletes/BingCampaignsMultiAutoComplete.vue'),
    SystemJobsTable: () => import("@/components/tables/SystemJobsTable.vue"),
  },

  methods: {
    accountChanged: function () {
      if (this.selectedAccountID) {
        localStorage.setItem(this.lsSelectedBingAccount, JSON.stringify(this.selectedAccountID))
      }

      //reinitialize previously selected campaigns
      this.selectedCampaigns = [];
    },

    accountsRefreshing: function () {
      this.selectedCampaigns = []
    },

    campaignsChanged: function () {
      if (this.selectedCampaigns) {
        localStorage.setItem(this.lsSelectedCampaigns, JSON.stringify(this.selectedCampaigns.map(a => a.campaign_id)))
      }
    },

    runRules: function (validateOnly) {
      this.showErrorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      var action = 'running'
      if (validateOnly) {
        this.validated = false
        action = 'validating'
      }

      this[action] = true

      const params = {
        campaign_ids: this.selectedCampaigns.map(a => a.campaign_id),
        campaign_names: this.selectedCampaigns.map(a => a.name),
        validate_only: validateOnly,
      }
      const body = JSON.stringify(params)

      this.$http.post(this.runImportRulesEndpoint, body).then((resp) => {
        if (resp.data.data.error_msgs && resp.data.data.error_msgs.length > 0) {
          this.errorMsg = this.forms_getValidationDisplay(resp.data.data.error_msgs, 'error(s)')
          this.showErrorAlert = true
        } else {
          this.validated = true
        }
        this.progressTableKey = this.$dateTime.now().toString()
      })
      .catch((errResp) => {
        this.validated = false
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.runImportRulesEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this[action] = false)
    },
  },

  created: function () {
    // if present, use local selected Bing account
    if (localStorage.getItem(this.lsSelectedBingAccount)) {
      this.selectedAccountID = JSON.parse(
        localStorage.getItem(this.lsSelectedBingAccount)
      )
    }
  },
}
</script>
